function variantsHavePrices(configurator) {
  return configurator.scenes.some((scene) =>
    scene.options.some((option) =>
      option.variants.some(
        (variant) => variant.price !== 0 && variant.price !== null && variant.price !== undefined,
      ),
    ),
  );
}

// -------------------------------------------------------------------------------------------

function getOptionSelection(fullCurrentOption, configuration) {
  const option = fullCurrentOption;
  const selection = {
    optionTitle: option.displayed_title,
  };

  // Get selection from configuration
  const matchingSelection = configuration.optionSelections.find(
    (item) => option.id === item.option.id,
  );
  if (!matchingSelection) {
    console.error(`Could not find selection for option ${option.title}`);
    return null;
  }
  selection.priceAtSelectionTime = matchingSelection.priceAtSelectionTime;

  // Find variant
  const variant = option.variants.find(
    (item) => item.id.toString() === matchingSelection.selectedVariant,
  );
  if (!variant) {
    console.error(`Could not find variant for option ${option.title}`);
    return null;
  }
  selection.variantTitle = variant.title;
  selection.variantPrice = variant.price;
  selection.order = option.order;

  return selection;
}

// -------------------------------------------------------------------------------------------

export default function getCartList(
  configurator,
  configuration,
  filterScenes,
  filterOptions,
  translate,
) {
  // Prepare the data
  const cartList = {
    sections: [
      {
        title: translate('cart.section.common'),
        options: [],
        isFloorplan: false,
      },
    ],
    priceTotal: 0,
    pricingEnabled: variantsHavePrices(configurator),
  };

  // Skip all dynamicly disabled scenes
  const filteredScenes = filterScenes(configurator.scenes);

  // Get all options that are used within multiple scenes of this configurator
  let commonOptions = [];
  filteredScenes.forEach((scene) => {
    filterOptions(scene.options).forEach((option) => {
      const commonOptionIndex = commonOptions.findIndex((item) => item.id === option.id);
      if (commonOptionIndex === -1) {
        commonOptions.push({
          ...option,
          usageCount: 1,
        });
      } else {
        commonOptions[commonOptionIndex].usageCount += 1;
      }
    });
  });
  commonOptions = commonOptions.reduce((accumulator, option) => {
    if (option.usageCount > 1) {
      accumulator.push(option);
    }
    return accumulator;
  }, []);
  // console.log('commonOptions', commonOptions);

  // Add common options to cart section
  commonOptions.forEach((option) => {
    cartList.sections[0].options.push(getOptionSelection(option, configuration, configurator));
  });

  // Add a section for each scene
  filteredScenes.forEach((scene) => {
    // Prepare the section
    const sceneSection = {
      title: scene.displayed_title,
      options: [],
    };

    // Add options to section
    filterOptions(scene.options).forEach((option) => {
      // Only add non-common options
      if (commonOptions.findIndex((item) => item.id === option.id) === -1) {
        sceneSection.options.push(getOptionSelection(option, configuration, configurator));
      }
    });

    // Add section to cart list
    sceneSection.isFloorplan = scene.scene_type === 'floorplan';
    if (sceneSection.isFloorplan) {
      cartList.sections.unshift(sceneSection);
    } else {
      cartList.sections.push(sceneSection);
    }
  });

  // Calculate the total cart price
  if (cartList.pricingEnabled) {
    cartList.sections.forEach((section) => {
      section.options.forEach((option) => {
        // console.log(option);
        cartList.priceTotal += option.variantPrice;
      });
    });
  }

  // Remove pricing if necessary
  if (!cartList.pricingEnabled) {
    for (let i = 0; i < cartList.sections.length; i += 1) {
      for (let j = 0; j < cartList.sections[i].options.length; j += 1) {
        delete cartList.sections[i].options[j].variantPrice;
      }
    }
  }

  // Order selections
  for (let i = 0; i < cartList.sections.length; i += 1) {
    cartList.sections[i].options.sort((a, b) => a.order - b.order);
  }

  return cartList;
}
